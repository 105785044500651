import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Filters = ({filter,handleChange}) => {

    const renderButtons = () => {
        return ['Name','Scheduled','Unscheduled'].map((item,index) => {
            return (
                <FormControlLabel
                value={item.toLowerCase()}
                control={<Radio color="primary" />}
                label={item}
                labelPlacement="end"
                key={index}
                />
            )
        })
    }

    return (
        <div className="filterContainer">
            <h2>Sort By: </h2>
            <RadioGroup aria-label="position" name="position" value={filter} onChange={handleChange} row>
                {renderButtons()}            
            </RadioGroup>
        </div>
    )
}

export default Filters;