import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@material-ui/icons/Info';

import './CustomAgenda.css'

const useStyles = makeStyles({
    table: {
        // minWidth: 450,
    },
    thead:{
        borderBottomColor: 'black',
        fontWeight: 'bold',
        fontSize: '17px'
    },
    bottomBorder: { 
        borderBottomColor: 'black'
    },
    firstColumn : {
        minWidth: '110px',
        // verticalAlign: 'top'
    },
    secondColumn : {
        minWidth: '95px',
    },
    icon: {
        position: 'absolute',
        top: '3px',
        right: '3px',
        fontSize: 20
    }
});

const CustomAgenda = ({ events, mode, handleEventClick }) => {
    const classes = useStyles();
    let eventsObj = {};
    let eventsToUse = [];
    events.forEach(event => {
        let key = event.dataThing.StaffId;
        if(mode == 'single') {
            key = event.dataThing.StartTime.substring(0,10);
        }
        eventsObj[key] = eventsObj[key] || [];
        if(["schedules","unScheduled"].indexOf(event.type)==-1){
            eventsObj[key].push(event);
            eventsObj[key] = eventsObj[key].sort((a,b) => {
                return moment(a.start).diff(moment(b.start))
            })
        }else {
            eventsToUse.push(event);
        }
    })

    const renderInfoIcon = event => {
        if(event.type != 'breaks')
            return <InfoIcon onClick={()=>handleEventClick(event)} className={classes.icon} />;
    }

    const renderBreaksAndBookings = keyName => {
        
        return eventsObj[keyName].map((event,index) => {
            let key = event.dataThing.StaffId;
            if(mode == 'single') {
                key = event.dataThing.StartTime.substring(0,10);
            }
            return (
                <TableRow key={key+index}>
                    <TableCell className={`${index == eventsObj[keyName].length-1 ? classes.bottomBorder : ''} ${classes.secondColumn}`}>{moment(event.start).format('h:mm a')} - {moment(event.end).format('h:mm a')}</TableCell>
                    <TableCell className={index == eventsObj[keyName].length-1 ? classes.bottomBorder : ''}>{event.title} {renderInfoIcon(event)} </TableCell>
                </TableRow>
            )
        })
    }

    const renderTime = event => {
        if(event.type == 'unScheduled') {
            return 'NA'
        }
        return `${moment(event.start).format('h:mm a')} - ${moment(event.end).format('h:mm a')}`
    }

    const renderTableBody = () => {
        return eventsToUse.map((item,index) => {
            let key = item.dataThing.StaffId;
            if(mode == 'single') {
                key = item.dataThing.StartTime.substring(0,10);
            }
            return (
                <>
                    <TableRow key={index}>
                        <TableCell className={`${classes.bottomBorder} ${classes.firstColumn}`} rowSpan={eventsObj[key].length+1}>{mode == 'single' ?  moment(key,'YYYY-MM-DD').format('dddd, MMMM DD, YYYY') : item.staffName}</TableCell>
                        <TableCell className={`${eventsObj[key].length==0 ? classes.bottomBorder : ''} ${classes.secondColumn}`}>{renderTime(item)}</TableCell>
                        <TableCell className={eventsObj[key].length==0 ? classes.bottomBorder : ''}>{item.title} </TableCell>
                    </TableRow>
                    {renderBreaksAndBookings(key)}
                </>
            )
        })
    }
    return (
        <div className="customAgenda">
            <div className="agendaBody">
                <Table className={classes.table} aria-label="simple table">
                    <TableHead >
                        <TableRow>
                            <TableCell className={`${classes.thead} ${classes.firstColumn}`}>{mode == 'single' ? 'Date' : 'Staff Name'}</TableCell>
                            <TableCell className={`${classes.thead} ${classes.secondColumn}`}>Time</TableCell>
                            <TableCell className={classes.thead}>Schedule</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderTableBody()}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default CustomAgenda;