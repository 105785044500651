import React, {useState, useEffect} from 'react';
import Copyright from './Copyright';
import Soap from './Soap';
import _ from 'underscore';
import {getItem, setItem} from '../localStorage';
import moment from 'moment';
// @material
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function Login({history}) {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(()=>{
    let isIE = !!document.documentMode;
    if(isIE) setError(window.config.unSupportedBrowserMessage);
  },[])

  function authenticateUser(e) {
    e.preventDefault();
    if(username.length == 0 || password.length == 0) return;
    setLoading(true);
    Soap('AuthUser', {
        UserId: username,
        Password: password
    }).then(function(a) {
      setLoading(false);
      let res = a.data.Envelope.Body.AuthUserResponse;
      if(res.Result._value == "FAIL") {
        setError(res.Result.Text);
        return;
      }
        let locationArray = [];
        // if (r_l.Application[1].value == "SPA") {
        [].concat(_.find([].concat(res.Application),loc => loc._value == "SPA").Location).forEach(function (e) {
            locationArray.push({id: e.__text, name: e._LocationName});
        })
        // }
        sessionStorage.setItem('userLoggedIn', true)
        sessionStorage.setItem('StaffId', res.StaffId)
        sessionStorage.setItem('UserGUID', res.UserGUID)
        sessionStorage.setItem('Name', res.Name)
        sessionStorage.setItem('ShortName', res.ShortName)
        sessionStorage.setItem('LocationList', JSON.stringify(locationArray))
        sessionStorage.setItem('mode','single');
        setItem({user:res.StaffId, key:'locationId', value:(getItem({user:res.StaffId, key:'locationId'}) || locationArray[0].id)});
        history.push(`/schedule/${getItem({user:res.StaffId, key:'locationId'})}/single/${moment().format('YYYY-MM-DD')}`);
    })
  }

  function renderError() {
    if(error.length >0) {
      return <Alert onClose={() => {setError('')}} severity="error">{error}</Alert>
    }
  }

  function renderLoader() {
    return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={loading}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
          <CircularProgress color="secondary" />
      </Modal>
    )
  }

  if(sessionStorage.getItem('userLoggedIn')) {
      history.push(`/schedule/${sessionStorage.getItem('locationId')}/${sessionStorage.getItem('mode')}/${moment().format('YYYY-MM-DD')}`);
      return <div></div>;
  }

  return (
    <Container component="main" maxWidth="xs">
      {renderLoader()}
      <CssBaseline />
      <div className={classes.paper}>
        {renderError()}
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {window.config.siteName}
        </Typography>
        <form className={classes.form} noValidate onSubmit={e=>authenticateUser(e)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            value = {username}
            onChange = {e => setUsername(e.target.value.toUpperCase())}
            label="User Name"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value = { password }
            onChange = {e => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            // onClick={()=>authenticateUser(username, password)}
          >
            Login
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Login;
