import React from 'react';
import moment from 'moment';

// material ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import './detailsBar.css';

const useStyles = makeStyles({
    card: {
      minWidth: 275,
      backgroundColor: '#d8d8d8',
      maxWidth: 390
    },
    closeIcon: {
        position: 'absolute',
        right: '5px',
        top: '4px',
        cursor: 'pointer'
    }
});

function renderClassCustomers(event) {
    if(event.type == 'classbookings' && window.config.showClassCustomers && event.dataThing.Bookings>0 && !!event.dataThing.ClassCustomers) {
        return (
            <>
                <Typography>{event.dataThing.Bookings} Customers</Typography>
                <ul>
                    {[].concat(event.dataThing.ClassCustomers.Customer).filter(customer => window.config.showServiceStatuses.indexOf(customer.BookStatus) > -1).map((customer,index) => <li key={customer.Firstname+index}>{customer.BookStatus} {customer.Firstname} {customer.Lastname}</li>)}
                </ul>
            </>
        )
    }
}

function renderBookingDetails(event) {
    if(['spabookings','cleanTime','classbookings'].indexOf(event.type)>-1) {
        let bool = true;
        if(event.dataThing.StaffId != sessionStorage.getItem('StaffId') && !!event.dataThing.StaffId) {
            bool = window.config.calendar.showOthersBookingDetails
        }
        if(bool){
            return (
                <>
                    <Typography>Status : {event.dataThing.Status}</Typography>
                    <Typography>Room : {event.dataThing.Room}</Typography>
                    {event.dataThing.CleanTime > 0 ? <Typography>Clean Time : {event.dataThing.CleanTime}</Typography> : null}
                    {event.dataThing.CustomerName ? <Typography>Customer : {event.dataThing.CustomerName}</Typography> : null}
                    { window.config.showServiceNote && (event.dataThing.SpaItemNote || '').length > 0 ? <Typography>Reference Note : {event.dataThing.SpaItemNote}</Typography> : null}
                    {renderClassCustomers(event)}
                </>
            )
        }
    }
}

function renderDetailsBody(event) {
    let location = event.dataThing.LocationName || event.dataThing.Location;
    if(!location) {
        let selectedLocation = sessionStorage.getItem('locationId');
        JSON.parse(sessionStorage.getItem('LocationList')).some(locationObj => {
            location = locationObj.name;
            return locationObj.id == selectedLocation;
        })
    }
    return (
        <>
            <Typography>Location : {location}</Typography>
            <Typography>Start : {moment(event.dataThing.StartTime,'YYYY-MM-DDHHmmss').format('hh:mm A')}</Typography>
            <Typography>End : {moment(event.dataThing.EndTime,'YYYY-MM-DDHHmmss').format('hh:mm A')}</Typography>
            {event.dataThing.BreakNote ? <Typography>{event.dataThing.BreakNote}</Typography> : null}
            {renderBookingDetails(event)}
        </>
    )
}
function renderPopupTitle(event){
    return(
         <Typography variant="h5" component="h2">
            {event.type == 'cleanTime' ? event.serviceName : event.title || 'Details'}
         </Typography>
    )

}
const DetailsBar = ({event,handleUnselectEvent}) => {
    const classes = useStyles();
    return (
        <div className="detailsBar">
            <CloseIcon className={classes.closeIcon} onClick={handleUnselectEvent} />
            <Card className={classes.card}>
                <CardContent>
                    {renderPopupTitle(event)}
                    {renderDetailsBody(event)}
                </CardContent>
            </Card>
        </div>
    )
}

export default DetailsBar;