import React from 'react';
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';

const Router = () => {
    
    return(
        <HashRouter>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/schedule/:locationId/:mode/:date" component={Dashboard} />
                <Redirect to={`/`} />
            </Switch>
        </HashRouter>
    )
}

export default Router;
