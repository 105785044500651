import React,{useEffect,useState,useRef} from 'react';
import Copyright from './Copyright';
import clsx from 'clsx';
import BigCalendar from './big calendar/BigCalendar';
import Soap from './Soap';
import moment from 'moment';
import _ from 'underscore';
import {Route,Switch,Redirect} from 'react-router-dom';
import AppBar from './big calendar/AppBar';
import { setItem } from '../localStorage';

// @material
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

moment.updateLocale('en', {
    week : {
        dow : window.config.calendar.startDayOfWeek === null ? moment().day() : window.config.calendar.startDayOfWeek,
     }
});

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // minWidth: '1000px'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function Dashboard({history,match}) {
    const classes = useStyles();
    const desktopMode = window.innerWidth > 480;
    const [open, setOpen] = useState(desktopMode);
    const [staffData, setStaffData] = useState({});

    const [dateRange, setDateRange] = useState({});


    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
            if(desktopMode){
                setLabelWidth(inputLabel.current.offsetWidth);
            }
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleRangeChange = range => {
        setDateRange(range);
    }

    const renderSingleCalendar = () => {
        return <BigCalendar mode='single' dateOnUrl={match.params.date} handleRangeChange={handleRangeChange} dateRange={dateRange} staffData = {staffData[`${dateRange.start} - ${dateRange.end} - ${match.params.locationId}`]} />;
    }

    const renderGroupCalendar = () => {
        if(window.config.sideBar.showGroupsOption){
            return <BigCalendar mode='group' dateOnUrl={match.params.date} handleRangeChange={handleRangeChange} dateRange={dateRange} staffData = {staffData[`${dateRange.start} - ${dateRange.end} - ${match.params.locationId}`]} />;
        }else {
            return <Redirect to={`/schedule/${match.params.locationId}/single/${match.params.date}`} />
        }
    }

    const navigateTo = (e,to) => {
        e.preventDefault();
        sessionStorage.setItem('mode',to);
        history.push(`/schedule/${match.params.locationId}/${to}/${match.params.date}`);
    }

    const handleSelectChange = val => {
        if(val){
            setItem({user:sessionStorage.getItem('StaffId'), key:'locationId', value:val});
            history.push(`/schedule/${val}/${match.params.mode}/${match.params.date}`);
        }
    }

    const renderGroupTab = () => {
        if(window.config.sideBar.showGroupsOption) {
            return (
                <Link color="inherit" onClick={e=>navigateTo(e,'group')}>
                    <ListItem button key='group'>
                        <ListItemIcon><GroupIcon /></ListItemIcon>
                        <ListItemText primary='Group' />
                    </ListItem>
                </Link>
            )
        }
    }

    useEffect(() => {
        if(!window.config.stayOnSelectedDateOnRefresh) history.push(`/schedule/${match.params.locationId}/${match.params.mode}/${moment().format('YYYY-MM-DD')}`)
    },[])

    useEffect(() => {
        if(['single','group'].indexOf(match.params.mode) < 0) return;
        let date = moment(match.params.date,'YYYY-MM-DD');
        let start;
        let end;
        if(match.params.mode === 'single') {
            start = moment(date).startOf('week').format('YYYY-MM-DDHHmmss');
            end = moment(date).endOf('week').format('YYYY-MM-DDHHmmss');
        }else {
            start = moment(date).startOf('day').format('YYYY-MM-DDHHmmss');
            end = moment(date).endOf('day').format('YYYY-MM-DDHHmmss');
        }
        setDateRange({start, end});
    },[match.params.mode, match.params.date]);

    useEffect(() => {
        if(!sessionStorage.getItem('StaffId') || !sessionStorage.getItem('UserGUID')) {
            history.push('/');
            return;
        }
        if(!!staffData[`${dateRange.start} - ${dateRange.end} - ${match.params.locationId}`] || _.isEmpty(dateRange) || ['single','group'].indexOf(match.params.mode) < 0) return;
        let params = {
            StartDate: dateRange.start,
            EndDate: dateRange.end,
            StaffId: sessionStorage.getItem('StaffId'),
            UserGUID: sessionStorage.getItem('UserGUID'),
        };
        if(match.params.mode === 'group') params.Location = match.params.locationId;
        Soap('FetchSpaSchedule', params).then(res => {
            let data = res.data.Envelope.Body.FetchSpaScheduleResponse;
            if(!!data.Schedules) data.Schedules.Schedule = _.sortBy([].concat(data.Schedules.Schedule),'StartTime');
            let unScheduled = [];
            let dataPerLocationObj = {
                [`${dateRange.start} - ${dateRange.end} - ${match.params.locationId}`] : {
                    breaks: [],
                    schedules: [],
                    spabookings: [],
                    classbookings: []
                }
            };
            if(match.params.mode === 'group') {
                let staffObj = {};
                [].concat(data.Schedules.Schedule || []).forEach(schedule => {
                    staffObj[schedule.StaffId] = true;
                });
                unScheduled = data.Staffs.Staff.filter(staff => {
                    if(staff._Bookable === 'Y' && !staffObj[staff._Id]){
                        staff.StaffId = staff._Id;
                        staff.StaffName = staff._Name;
                        return true;
                    }
                    return false;
                });
            }
            if(match.params.mode === 'single') {
                ['Breaks','Schedules','SpaBookings','ClassBookings'].forEach(key => {
                    let dataArr = (data[key] || {})[key.substring(0,key.length-1)] || [];
                    [].concat(dataArr).forEach(item => {
                        let dateLocationKey = `${dateRange.start} - ${dateRange.end} - ${item.Location || item.LocationId}`;
                        dataPerLocationObj[dateLocationKey] = dataPerLocationObj[dateLocationKey] || {};
                        dataPerLocationObj[dateLocationKey][key.toLocaleLowerCase()] = dataPerLocationObj[dateLocationKey][key.toLocaleLowerCase()] || [];
                        dataPerLocationObj[dateLocationKey][key.toLocaleLowerCase()].push(item);
                    })
                });
                setStaffData( prevStaffData => (
                    {
                        ...prevStaffData,
                        ...dataPerLocationObj
                    }
                ));
            }else {
                setStaffData( prevStaffData => (
                    {
                        ...prevStaffData,
                        [`${dateRange.start} - ${dateRange.end} - ${match.params.locationId}`] : {
                            breaks : [].concat(data.Breaks.Break || []),
                            schedules : [].concat(data.Schedules.Schedule || []),
                            spabookings : [].concat(data.SpaBookings.SpaBooking || []),
                            classbookings : [].concat(data.ClassBookings.ClassBooking || []),
                            unScheduled
                        }
                    }
                ));
            }
        })
    },[dateRange, match.params.locationId]);

  return (
    <div className={classes.root}>
        <CssBaseline />
        <AppBar handleDrawerOpen={handleDrawerOpen} open={open} history={history} match={match} />
        <Drawer
            variant={desktopMode ? "permanent" : null}
            classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
            onClose={handleDrawerClose}
        >
            <div className={classes.toolbarIcon}>
            Welcome {sessionStorage.getItem('Name')}
            <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
            </IconButton>
            </div>
            <Divider />
            <List>
                <Link color="inherit" onClick={e=>navigateTo(e,'single')}>
                    <ListItem button key='me'>
                        <ListItemIcon><PersonIcon /></ListItemIcon>
                        <ListItemText primary='Me' />
                    </ListItem>
                </Link>
                {renderGroupTab()}
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} htmlFor="outlined-location-native-simple">
                        Location
                    </InputLabel>
                    <Select
                        native
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={match.params.locationId}
                        onChange={e=>handleSelectChange(e.target.value)}
                        labelWidth={labelWidth}
                        inputProps={{
                            name: 'age',
                            id: 'outlined-age-native-simple',
                        }}
                    >
                        {
                            JSON.parse(sessionStorage.getItem('LocationList')||'[]').map(location => <option key={location.id} value={location.id}>{location.name} - {location.id}</option>)
                        }
                    </Select>
                </FormControl>
            </List>
        </Drawer>
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container className={classes.container}>
                <Switch>
                    <Route exact path={`/schedule/${match.params.locationId}/single/${match.params.date}`} component={renderSingleCalendar}/>
                    <Route exact path={`/schedule/${match.params.locationId}/group/${match.params.date}`} component={renderGroupCalendar}/>
                    <Route component={()=><Redirect to={`/schedule/${match.params.locationId}/single/${match.params.date}`} />}/>
                </Switch>
            </Container>
            <Copyright />
        </main>
    </div>
  );
}
