import axios from 'axios';
import x2js from 'x2js';

var url = '/wso2wsas/services/RSOPS/RSOPS';

export default function soapClient(method,params) {
    // let namespace = 'xmlns: ops = "http://www.resortsuite.com/RSOPS"';
    let xmlstr = new x2js().js2xml(params);
    return axios( {
        method: "POST",
        url,
        headers: {
            'SOAPAction': method,
            'Content-Type': 'text/xml; charset="UTF-8"',
            'Accept': 'application/xml'
        },
        data: '<?xml version="1.0" encoding="UTF-8" ?><soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ops="http://www.resortsuite.com/RSOPS" ><soapenv:Header><o:Security soapenv:mustUnderstand="1" xmlns:o="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd"><o:UsernameToken><o:Username>resortsuite</o:Username><o:Password>resortsuite</o:Password></o:UsernameToken></o:Security></soapenv:Header><soapenv:Body><ops:' + method + 'Request>' + xmlstr + '</ops:' + method + 'Request></soapenv:Body></soapenv:Envelope>',
        // data: '<?xml version="1.0" encoding="UTF-8" ?><soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" ' + xmlns.join(' ') + '><soapenv:Header /><soapenv:Body><' + namespace + ':' + method + 'Request>' + xmlstr + '</' + namespace + ':' + method + 'Request></soapenv:Body></soapenv:Envelope>',
        transformResponse: function (data) {
            return new x2js().xml2js(data);
        },
        cache: false,
        timeout: 15000
    })
    
}
