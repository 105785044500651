import React,{useCallback, useState} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from "moment";
import _ from 'underscore';
import CustomView from './CustomView';
import { withRouter } from 'react-router-dom';

const localizer = momentLocalizer(moment);


const MyCalendar = ({ mode, events, resourceMap, mobileMode, handleEventClick, dateOnUrl, history }) => {
    let {slotMinutes, minTime, maxTime, wideAvailableViews, wideDefaultView, narrowDefaultView, narrowAvailableViews} = window.config;
    let currentView = sessionStorage.getItem('currentView');

    let [style, setStyle] = useState({height: '100%' });
    let [view, setView] = useState();
    let [windowHeight,setwindowHeight] = useState(window.innerHeight);
    window.addEventListener("resize", ()=>{
        setwindowHeight(window.innerHeight);
    });

    let calendarRef = useCallback(el => {
        let style = {height: '100%', minHeight: 'unset' };
        if (el !== null && fetchDefaultView() != 'agenda') {
            let num = mobileMode ? 16 : 76;
            style.height = `${window.innerHeight - el.getBoundingClientRect().top - num}px`;
            style.minHeight = '450px';
        }
        setStyle(style);
    }, [windowHeight,view]);

    let fetchViews = () => {
        let views = mobileMode ? narrowAvailableViews : wideAvailableViews;
        let viewsObj = {};
        if(mode == 'group') {
            views = ['day','agenda'];
        }
        views.forEach(item => {
            if(item == 'agenda'){
                viewsObj[item] = CustomView;
            } else {
                viewsObj[item] = true;
            }
        })
        return viewsObj;
    }

    let Event = ({ event }) => {
        return (
            <div className="customEvent" /*onMouseEnter={()=>handleEventClick(event)} */>
                <strong>{event.title}</strong>
            </div>
        )
    }

    let fetchDefaultView = () => {
        let viewInConfig = mobileMode ? narrowDefaultView : wideDefaultView;
        let view = Object.keys(fetchViews()).indexOf(currentView) > -1 ? currentView : viewInConfig ;
        if(mode == 'group') {
            if(['day','agenda'].indexOf(view) < 0) view = 'day';
        }
        return view;
        // mode == 'group' ? Views.DAY : (mobileMode ? Views.AGENDA : (currentView || wideDefaultView))
    }

    return (
        <div className="calendarContainer" ref={calendarRef} style={style} >
            <Calendar
                eventPropGetter={event => {
                    if (event.type === "breaks") {
                        return { className: 'break' }
                    }
                    if (event.type === "spabookings") {
                        return { className: 'spaBooking' }
                    }
                    if (event.type === "classbookings") {
                        return { className: 'classBooking' }
                    }
                    if (event.type === "cleanTime" && event.applyAlignmentFix) {
                        return { className: 'cleantimeAlignmentFix ' }
                    }
                    if (event.type === "cleanTime") {
                        return { className: 'clean ' }
                    }
                   
                    return { className: 'schedule' }
                }}
                onNavigate = {(date) => {
                    history.replace(`/schedule/${sessionStorage.getItem('locationId')}/${mode}/${moment(date).format('YYYY-MM-DD')}`);
                }}
                onRangeChange = {(dateRangeArr, view)=>{
                    if(!view) return;
                    sessionStorage.setItem('currentView',view);
                    setView(view);
                }}
                onSelectEvent = {event => {
                    handleEventClick(event)
                }}
                events={events}
                localizer={localizer}
                defaultView={fetchDefaultView()}
                views={fetchViews()}
                step={slotMinutes}
                defaultDate={ moment(dateOnUrl,'YYYY-MM-DD').toDate() }
                resources={mode == 'group' ? resourceMap : null}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                min={new Date(moment(minTime, 'HH:mm'))}
                max = {new Date(moment(maxTime, 'HH:mm'))}
                tooltipAccessor={null}
                timeslots={1}
                mode = {mode}
                handleEventClick = {handleEventClick}
                components={{
                    event: Event,
                }}
            />
        </div>
    )
}

export default withRouter(MyCalendar);