import React, { PureComponent } from "react";
import moment from "moment";
import _ from 'underscore';
import Filters from './Filters';
import Calendar from './Calendar';
import DetailsBar from './detailsBar/DetailsBar';
import {getItem, setItem} from '../../localStorage';
import { withRouter } from 'react-router-dom';

// @material
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import "react-big-calendar/lib/css/react-big-calendar.css";
import './BigCalendar.css';

class MySchedule extends PureComponent {

    state = {
        events: [],
        resourceMap: [],
        loading: true,
        filter: getItem({user:sessionStorage.getItem('StaffId'), key:'groupFilter'}) || 'scheduled',
        mobileMode: window.innerWidth <= 480,
        selectedEvent: {},
    }

    componentDidMount() {
        let eventTitleMap = {
            schedules: 'Scheduled',
            breaks : 'Break'
        }

        window.addEventListener("resize", this.resize.bind(this));
        let {staffData, mode} = this.props;
        if(!staffData) return;
        staffData = JSON.parse(JSON.stringify(staffData));
        let events = [];
        let resourceMap = [];
        let staffObj = {};
        _.keys(staffData).forEach(key => {
            _.compact(staffData[key]).forEach((item,index) => {
                let bool = true;
                if(key == 'spabookings') {
                    bool = window.config.showServiceStatuses.indexOf(item.Status)>-1 && !window.config.hideAppointments;
                    if(bool && item.StaffId != sessionStorage.getItem('StaffId') && mode == 'group'){
                        bool = window.config.calendar.showOthersBookings;
                    }
                }

                if(key=="classbookings") {
                    bool = !window.config.hideAppointments;
                    if(bool && item.StaffId != sessionStorage.getItem('StaffId') && mode == 'group'){
                        bool = window.config.calendar.showOthersBookings;
                    }
                }

                if(bool) {
                    events.push(
                        {
                            id: index,
                            title: key == 'classbookings' ? item.ClassName : (item.ItemName || eventTitleMap[key] || key),
                            start: moment(item.StartTime,'YYYY-MM-DDHHmmss').toDate(),
                            end: moment(item.EndTime,'YYYY-MM-DDHHmmss').toDate(),
                            resourceId: item.StaffId,
                            type: key,
                            dataThing: item,
                            staffName: item.StaffName
                        }
                    );
                    if(item.CleanTime>0) {
                        var cleantime = parseInt(item.CleanTime)
                        events.push(
                            {
                                id: index,
                                title: 'clean time',
                                start: moment(item.EndTime,'YYYY-MM-DDHHmmss').toDate(),
                                end: moment(item.EndTime,'YYYY-MM-DDHHmmss').add(cleantime,'minutes').toDate(),
                                resourceId: item.StaffId,
                                type: 'cleanTime',
                                dataThing: item,
                                staffName: item.StaffName,
                                serviceName: key == 'classbookings' ? item.ClassName : item.ItemName,
                                applyAlignmentFix: cleantime < window.config.slotMinutes 

                            }
                        );
                    }
                }
                if(!staffObj[item.StaffId]) {
                    resourceMap.push({
                        resourceId: item.StaffId, 
                        resourceTitle: item.StaffName,
                        scheduled: key != 'unScheduled'
                    });
                    staffObj[item.StaffId] = true;
                }
            })
        });
        if(mode == 'group'){
            [events,resourceMap] = this.sortEvents(events, resourceMap, this.state.filter);
        }
        this.setState({ events, resourceMap, loading:false });
    }

    resize() {
        this.setState({mobileMode: window.innerWidth <= 400});
    }

    sortEvents = (events, resourceMap, filter) => {
        events = _.sortBy(events,'staffName');
        resourceMap = _.sortBy(resourceMap,'resourceTitle');
        
        if(filter=='name') {
            return [events, resourceMap];
        }

        let dict = {scheduled:[], unScheduled:[]};
        resourceMap.forEach(x=>{
            let key = x.scheduled ? 'scheduled' : 'unScheduled';
            dict[key].push(x);
        });

        if(filter=='scheduled') {
            let sortedEvents = [...events].sort((a,b) => {
                if(a.type==b.type) return 0;
                if(a.type == "schedules") return -1;
                return 1;
            });
            return [sortedEvents, [...dict.scheduled, ...dict.unScheduled]];
        }

        let sortedEvents = [...events].sort((a,b) => {
            if(a.type==b.type) return 0;
            if(a.type == "unScheduled") return -1;
            return 1;
        });
        return [sortedEvents, [...dict.unScheduled, ...dict.scheduled]]
    }

    handleFilterChange = e => {
        let selectedFilter = e.target.value;
        setItem({user:sessionStorage.getItem('StaffId'), key:'groupFilter', value:selectedFilter});
        let [events,resourceMap] = this.sortEvents(this.state.events, this.state.resourceMap, selectedFilter);
        this.setState({filter: selectedFilter,events,resourceMap});
    }

    handleEventClick = event => {
        this.setState({selectedEvent : event});
    }

    handleUnselectEvent = () => {
        this.setState({selectedEvent : {}});
    }

    renderFilters = () => {
        if(this.props.mode == 'group') {
            return (<Filters filter={this.state.filter} handleChange={e => this.handleFilterChange(e)} />)
        }
    }

    changeDateOnDatePicker = dateString => {
        this.setState({dateOnDatePicker: dateString})
    }    

    handleDateChange = (date) => {
        let  { history, mode } = this.props;
        history.replace(`/schedule/${sessionStorage.getItem('locationId')}/${mode}/${moment(date).format('YYYY-MM-DD')}`)
    }

    renderDatePicker = () => {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Select Date"
                    format="MMM D, YYYY"
                    value={moment(this.props.dateOnUrl,'YYYY-MM-DD')}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date'
                    }}
                />
            </MuiPickersUtilsProvider>
        )
    }

    renderCalendar = () => {
        return <Calendar {...this.props} handleEventClick={this.handleEventClick} mobileMode={this.state.mobileMode}  events={this.state.events} resourceMap={this.state.resourceMap} />
    }

    renderDetailsBar = () => {
        const {selectedEvent} = this.state;
        if(!_.isEmpty(selectedEvent))
            return <DetailsBar event={selectedEvent} handleUnselectEvent={this.handleUnselectEvent} />
    }

  render() {

    if(this.state.loading){
        return <h1>Loading</h1>;
    }

    return (
        <div className="Calendar">
            {this.renderDatePicker()}
            {this.renderFilters()}
            {this.renderCalendar()}
            {this.renderDetailsBar()}
        </div>
    );
  }
}

export default withRouter(MySchedule);
