export const setItem = ({user, key, value}) => {
    let locationData = JSON.parse(localStorage.getItem('activeLocationPerUser')) || {};
    locationData[user] = locationData[user] || {};
    locationData[user][key] = value;
    if(key == 'locationId') sessionStorage.setItem('locationId',value);
    localStorage.setItem('activeLocationPerUser', JSON.stringify(locationData));
}

export const getItem = ({user, key}) => {
    let locationData = JSON.parse(localStorage.getItem('activeLocationPerUser')) || {};
    return (locationData[user] || {})[key];
}