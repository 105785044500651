import React,{Component} from 'react'
import { Navigate } from 'react-big-calendar'
import moment from 'moment';
import CustomAgenda from './customAgenda/CustomAgenda';




class MyWeek extends Component {
    
  render() {
      if(this.props.events.filter(item => ["schedules","unScheduled"].indexOf(item.type)>-1 ).length == 0) {
          return <h3 className="noScheduleText">No Schedules on the selected date range</h3>
      }
    return (
        <CustomAgenda {...this.props} />
    )
  }
}

MyWeek.range = (date) => {
    let range = [date]
    if(sessionStorage.getItem('mode') == 'single'){
        for(let i=1;i<7;i++) {
            range.push(moment(date).add(i,'d').toDate());
        }
    }
    return range
}

MyWeek.navigate = (date, action) => {
    let daysToAdd = sessionStorage.getItem('mode') == 'group' ? 1 : 7;
    switch (action) {
        case Navigate.PREVIOUS:
        return moment(date).subtract(daysToAdd,'d')

        case Navigate.NEXT:
        return moment(date).add(daysToAdd,'d');

        default:
        return date
    }
}

MyWeek.title = date => {
    if(sessionStorage.getItem('mode') == 'group') {
        return moment(date).format('dddd, MMM DD, YYYY');
    }
    return `${moment(date).format('MMM DD')} - ${moment(date).add(7,'d').format('MMM DD YYYY')}`
}


export default MyWeek;